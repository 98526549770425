import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";
import { parse, stringify } from "superjson";

export const pinia = createPinia().use(
  createPersistedState({
    serializer: {
      serialize: stringify,
      deserialize: parse
    }
  })
);

export * from "./local-stream";
export * from "./use-conference";
export * from "./use-device-management";
export * from "./use-participant";
export * from "./use-presentation";
export * from "./use-session";
export * from "./use-toast";
export * from "./use-video-layout";
