import type { NavigationGuard } from "vue-router";

import { useSession } from "@/stores";

export const meetingRoomGuard: NavigationGuard = () => {
  const { alias, startsAt } = useSession();

  if (startsAt && startsAt > new Date()) {
    return {
      name: "waiting-room",
      params: { alias }
    };
  }
};
