import type { NavigationGuard } from "vue-router";

import { useSession } from "@/stores";

export const waitingRoomGuard: NavigationGuard = () => {
  const { alias, startsAt } = useSession();

  if (!startsAt) {
    return {
      name: "preflight",
      params: { alias }
    };
  }
};
