import { isReceivingAudio, isReceivingVideo, isSendingAudio, isSendingVideo } from "@pexip/infinity";
import { computed, type MaybeRefOrGetter, toValue } from "vue";

import { getClientCallTypeFromCallType } from "@/helpers";
import type { CallType } from "@/types";

export function useCallType(callType: MaybeRefOrGetter<CallType>) {
  const clientCallType = computed(() => getClientCallTypeFromCallType(toValue(callType)));

  return {
    clientCallType,
    isReceivingAudio: computed(() => isReceivingAudio(clientCallType.value)),
    isReceivingVideo: computed(() => isReceivingVideo(clientCallType.value)),
    isSendingAudio: computed(() => isSendingAudio(clientCallType.value)),
    isSendingVideo: computed(() => isSendingVideo(clientCallType.value))
  };
}
