import { storeToRefs } from "pinia";
import { type NavigationGuard } from "vue-router";
import { z } from "zod";

import { removeQueryParams } from "@/router";
import { useDeviceManagement } from "@/stores";

const schema = z.object({
  devices: z
    .string()
    .base64()
    .optional()
    .catch(({ error }) => void console.error(error)),
  preffered_speaker: z.string().optional(), // Legacy (replaced by devices)
  preffered_microphone: z.string().optional(), // Legacy (replaced by devices)
  preffered_camera: z.string().optional() // Legacy (replaced by devices)
});

const schemaWithTransform = schema.transform((data) => {
  if (data.devices) {
    const [audioOutputDeviceId, audioInputDeviceId, videoInputDeviceId] = atob(data.devices).split(",", 3);

    return {
      audioOutputDeviceId,
      audioInputDeviceId,
      videoInputDeviceId
    };
  }

  return {
    audioOutputDeviceId: data.preffered_speaker,
    audioInputDeviceId: data.preffered_microphone,
    videoInputDeviceId: data.preffered_camera
  };
});

const SCHEMA_KEYS = Object.keys(schema.shape);

export const deviceManagementGuard: NavigationGuard = (to) => {
  const { error, data } = schemaWithTransform.safeParse(to.query);

  if (error) {
    console.error(error);
    return;
  }

  const { preferredVideoInputDeviceId, preferredAudioInputDeviceId, preferredAudioOutputDeviceId } =
    storeToRefs(useDeviceManagement());

  const { videoInputDeviceId, audioInputDeviceId, audioOutputDeviceId } = data;

  if (videoInputDeviceId) {
    preferredVideoInputDeviceId.value = videoInputDeviceId;
  }

  if (audioInputDeviceId) {
    preferredAudioInputDeviceId.value = audioInputDeviceId;
  }

  if (audioOutputDeviceId) {
    preferredAudioOutputDeviceId.value = audioOutputDeviceId;
  }

  if (SCHEMA_KEYS.some((key) => key in to.query)) {
    return {
      query: removeQueryParams(to.query, SCHEMA_KEYS)
    };
  }
};
