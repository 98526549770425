import { useEventListener } from "@vueuse/core";
import { computed, shallowRef } from "vue";

export function useDevicesList(constraints: MediaStreamConstraints = { audio: true, video: true }) {
  const devices = shallowRef<MediaDeviceInfo[]>([]);
  const videoInputs = computed(() => devices.value.filter((device) => device.kind === "videoinput"));
  const audioInputs = computed(() => devices.value.filter((device) => device.kind === "audioinput"));
  const audioOutputs = computed(() => devices.value.filter((device) => device.kind === "audiooutput"));

  async function updateDevices(allowRequestPermissions = false) {
    // Browsers require getUserMedia() to be called before enumerateDevices() to prevent fingerprinting,
    // which used to be a method for tracking users by calling enumerateDevices en masse without user consent.
    // To enhance user privacy, enumerating devices first, without user permission, no longer lists all devices.
    if (allowRequestPermissions) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints).catch(async (error) => {
          console.error(error);

          // Try to get the devices again but separately
          // This is to prevent the case where no devices are listed when permissions are granted
          // Example: OS settings are not permitted to access camera or microphone but the user has granted permissions in browser
          const [audioOnly, videoOnly] = await Promise.allSettled([
            navigator.mediaDevices.getUserMedia({ audio: constraints.audio }),
            navigator.mediaDevices.getUserMedia({ video: constraints.video })
          ]);

          if (audioOnly.status === "fulfilled") {
            audioOnly.value.getTracks().forEach((track) => track.stop());
          }

          if (videoOnly.status === "fulfilled") {
            videoOnly.value.getTracks().forEach((track) => track.stop());
          }
        });

        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      } catch (err) {
        console.error(err);
      }
    }

    devices.value = await navigator.mediaDevices.enumerateDevices();
  }

  async function requestPermissions() {
    await updateDevices(true);
  }

  useEventListener(navigator.mediaDevices, "devicechange", () => updateDevices());

  void updateDevices();

  return {
    devices,
    videoInputs,
    audioInputs,
    audioOutputs,
    updateDevices,
    requestPermissions
  };
}
