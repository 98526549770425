import { type NavigationGuard } from "vue-router";
import { z } from "zod";

import { removeQueryParams } from "@/router";
import { useSession } from "@/stores";

/**
 * URL Test Cases:
 *
 * 1. Basic Test:
 *    - URL: https://localhost:5173/medware@rooms.vconf.dk?pin=1020&muteMicrophone=true
 *
 * 2. Replace alias with conference:
 *    - URL: https://localhost:5173/medware@rooms.vconf.dk?pin=1020&muteMicrophone=true&name=Andreas&conference=meet.alice@pexip.com
 *
 * 3. Use conference as alias:
 *    - URL: https://localhost:5173/?pin=1020&muteMicrophone=true&name=Andreas&conference=medware@rooms.vconf.dk
 *
 * 4. Start date in the future with conference:
 *    - URL: https://localhost:5173/?pin=1020&muteMicrophone=true&name=Andreas&startsAt=2025-10-30T12:51:54&conference=meet.alice@pexip.com
 */

const schema = z.object({
  alias: z.string().optional(),
  conference: z.string().optional(), // Same as alias
  url: z.string().optional(), // Legacy (replaced by conference)
  name: z.string().optional(),
  pin: z.string().optional(),
  bandwidth: z
    .number({ coerce: true })
    .min(256)
    .max(6144)
    .optional()
    .catch(({ error }) => void console.error(error)),
  callType: z
    .enum(["audiovideo", "videosendonly", "audiosendonly"])
    .optional()
    .catch(({ error }) => void console.error(error)),
  startsAt: z
    .date({ coerce: true })
    .optional()
    .catch(({ error }) => void console.error(error)),
  start_dato: z // Legacy (replaced by startsAt)
    .date({ coerce: true })
    .optional()
    .catch(({ error }) => void console.error(error)),
  redirectTo: z
    .string()
    .url()
    .optional()
    .catch(({ error }) => void console.error(error)),
  redirect_url: z // Legacy (replaced by redirectTo)
    .string()
    .url()
    .optional()
    .catch(({ error }) => void console.error(error)),
  muteMicrophone: z
    .string()
    .transform((value) => ["true"].includes(value))
    .optional(),
  microphone: z // Legacy (replaced by muteMicrophone)
    .string()
    .transform((value) => ["muted", "off"].includes(value))
    .optional(),
  muteCamera: z
    .string()
    .transform((value) => ["true"].includes(value))
    .optional(),
  join: z
    .string()
    .transform((value) => ["1"].includes(value))
    .optional()
});

const schemaWithTransform = schema.transform((data) => ({
  alias: data.conference ?? data.url ?? data.alias,
  participantName: data.name,
  startsAt: data.startsAt ?? data.start_dato,
  pin: data.pin,
  bandwidth: data.bandwidth,
  callType: data.callType,
  redirectTo: data.redirectTo ?? data.redirect_url,
  muteMicrophone: data.muteMicrophone ?? data.microphone,
  muteCamera: data.muteCamera,
  join: data.join ?? (data.startsAt !== undefined || data.start_dato !== undefined ? true : undefined)
}));

const SCHEMA_KEYS = Object.keys(schema.shape);

let hasGuardRun = false;

export const sessionGuard: NavigationGuard = (to) => {
  // Only attempt to parse session parameters on initial load
  if (hasGuardRun) {
    return;
  }

  hasGuardRun = true;

  const { error, data } = schemaWithTransform.safeParse({
    ...to.params,
    ...to.query
  });

  if (error) {
    console.error(error);
    return;
  }

  const sessionStore = useSession();

  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([, value]) => {
      return value !== undefined;
    })
  );

  Object.assign(sessionStore.$state, filteredData);

  if (SCHEMA_KEYS.some((key) => key in to.query)) {
    return {
      query: removeQueryParams(to.query, SCHEMA_KEYS)
    };
  }
};
