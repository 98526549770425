import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";

import type { CallType } from "@/types";

type State = {
  participantName: string;
  alias: string;
  pin: string | undefined;
  redirectTo: string | undefined;
  muteMicrophone: boolean;
  muteCamera: boolean;
  callType: CallType;
  bandwidth: number;
  startsAt: Date | undefined;
  join: boolean;
};

export const useSession = defineStore(
  "session",
  () => {
    const state = reactive<State>({
      participantName: "Deltager",
      alias: "",
      pin: undefined,
      redirectTo: undefined,
      muteMicrophone: false,
      muteCamera: false,
      callType: "audiovideo",
      bandwidth: 1264,
      startsAt: undefined,
      join: false
    });

    const roomName = computed(() => state.alias.split("@")[0] ?? "");
    const domain = computed(() => state.alias.split("@")[1] || window.location.host);
    const fullAlias = computed(() => `${roomName.value}@${domain.value}`);

    return {
      ...toRefs(state),
      roomName,
      domain,
      fullAlias
    };
  },
  {
    persist: [
      {
        storage: localStorage,
        pick: ["participantName", "alias"]
      },
      {
        storage: sessionStorage,
        omit: ["participantName", "alias"]
      }
    ]
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSession, import.meta.hot));
}
