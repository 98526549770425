import { computed, onScopeDispose, shallowRef } from "vue";

type UsePermissionOptions = {
  queryInterval?: number | false;
};

/**
 * Safari does not seem to trigger 'onchange' event on permissions API, even though though
 * this resource suggests it should: https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
 *
 * As a work around this composable adds support via polling.
 */
export function usePermission(name: "camera" | "microphone", options: UsePermissionOptions = {}) {
  const { queryInterval = 1000 } = options;

  const state = shallowRef<PermissionState>("prompt");

  const isGranted = computed(() => state.value === "granted");
  const isDenied = computed(() => state.value === "denied");
  const isPrompt = computed(() => state.value === "prompt");

  async function query() {
    const permissionStatus = await navigator.permissions.query({ name } as unknown as PermissionDescriptor);

    permissionStatus.addEventListener("change", (status) => {
      state.value = (status.target as PermissionStatus).state;
    });

    state.value = permissionStatus.state;
  }

  void query();

  if (queryInterval) {
    const interval = setInterval(query, queryInterval);

    onScopeDispose(() => clearInterval(interval));
  }

  return {
    state,
    isGranted,
    isDenied,
    isPrompt
  };
}
