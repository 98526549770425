import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, type MaybeRefOrGetter, ref, toValue, watch } from "vue";

import { useCallType, useDevicesList, usePermission } from "@/composables";
import { useSession } from "@/stores";

/**
 * Composable that returns a media device from the provided list of devices.
 * It optionally uses a preferred device ID to prioritize a specific device. If a preferred device
 * ID is not provided or is not found, the first available device in the list will be returned as fallback.
 */
function useDevice(devices: MaybeRefOrGetter<MediaDeviceInfo[]>, preferredDeviceId = ref<string>()) {
  const device = computed(() => {
    const resolvedDevices = toValue(devices);

    if (preferredDeviceId.value) {
      return resolvedDevices.find(({ deviceId }) => deviceId === preferredDeviceId.value) ?? resolvedDevices[0];
    }

    return resolvedDevices[0];
  });

  const deviceId = computed(() => device.value?.deviceId);

  return {
    device,
    deviceId,
    preferredDeviceId
  };
}

export const useDeviceManagement = defineStore(
  "device-management",
  () => {
    const sessionStore = useSession();
    const { isSendingAudio, isSendingVideo } = useCallType(() => sessionStore.callType);

    const { devices, videoInputs, audioInputs, audioOutputs, updateDevices, requestPermissions } = useDevicesList({
      video: isSendingVideo.value,
      audio: isSendingAudio.value
    });

    const { preferredDeviceId: preferredVideoInputDeviceId, deviceId: videoInputDeviceId } = useDevice(videoInputs);
    const { preferredDeviceId: preferredAudioInputDeviceId, deviceId: audioInputDeviceId } = useDevice(audioInputs);
    const { preferredDeviceId: preferredAudioOutputDeviceId, deviceId: audioOutputDeviceId } = useDevice(audioOutputs);

    const { isGranted: hasCameraPermission } = usePermission("camera");
    const { isGranted: hasMicrophonePermission } = usePermission("microphone");

    // As of now, there is no permission API for speakers, so we assume that when the microphone is granted, the availability of other audio sources is also granted
    const hasSpeakerPermission = computed(() => hasMicrophonePermission.value);

    watch([hasCameraPermission, hasMicrophonePermission], () => updateDevices());

    return {
      devices,
      videoInputs,
      audioInputs,
      audioOutputs,
      preferredVideoInputDeviceId,
      preferredAudioInputDeviceId,
      preferredAudioOutputDeviceId,
      videoInputDeviceId,
      audioInputDeviceId,
      audioOutputDeviceId,
      hasCameraPermission,
      hasMicrophonePermission,
      hasSpeakerPermission,
      requestPermissions
    };
  },
  {
    persist: {
      storage: localStorage,
      pick: ["preferredVideoInputDeviceId", "preferredAudioInputDeviceId", "preferredAudioOutputDeviceId"]
    }
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceManagement, import.meta.hot));
}
