import { createRouter, createWebHistory, type LocationQuery } from "vue-router";

import { deviceManagementGuard, meetingRoomGuard, sessionGuard, waitingRoomGuard } from "@/router/guards";
import { useSession } from "@/stores";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: () => {
        const sessionStore = useSession();

        return {
          name: "preflight",
          params: { alias: sessionStore.alias }
        };
      }
    },
    {
      path: "/:alias?",
      name: "preflight",
      component: () => import("@/views/PreFlight.vue"),
      beforeEnter: [meetingRoomGuard]
    },
    {
      path: "/:alias/meeting-room",
      name: "meeting-room",
      component: () => import("@/views/ConferenceCall.vue"),
      beforeEnter: [meetingRoomGuard]
    },
    {
      path: "/:alias/waiting-room",
      name: "waiting-room",
      component: () => import("@/views/WaitingRoom.vue"),
      beforeEnter: [waitingRoomGuard]
    },
    {
      path: "/:notFound(.*)",
      redirect: "/"
    }
  ]
});

router.beforeEach(deviceManagementGuard);
router.beforeEach(sessionGuard);

export function removeQueryParams(query: LocationQuery, keys: string[]) {
  const entries = Object.entries(query);
  const filteredEntries = entries.filter(([key]) => !keys.includes(key));

  return Object.fromEntries(filteredEntries);
}
